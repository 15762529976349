import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/home/Home.page.vue'
import PickCompany from '../views/login/PickCompany.page.vue'
import PickAddress from '../views/login/PickAddress.page.vue'
import Login from '../views/login/Login.page.vue'
import Register from '../views/login/Register.page.vue'
import RequestPassword from '../views/login/RequestPassword.page.vue'
import ResetPassword from '../views/login/ResetPassword.page.vue'
import AccessGranted from '../views/login/GrantedAccess.page.vue'
import ShoppingCart from '../views/shopping-cart/Shopping-cart.page.vue'
import CheckoutDelivery from '../views/shopping-cart/checkout/delivery-options/Delivery-options.page.vue'
import CheckoutBilling from '../views/shopping-cart/checkout/billing-options/Billing-options.page.vue'
import CheckoutRemarks from '../views/shopping-cart/checkout/remarks/Remarks.page.vue'
import CheckoutOverview from '../views/shopping-cart/checkout/order-overview/Order-overview.page.vue'
import CheckoutOrderConfirmation from '../views/shopping-cart/checkout/order-confirmation/Order-confirmation.page.vue'
import MyaccountBilling from '../views/my-account/billing-info/Billing-info.page.vue'
import MyaccountOrderHistory from '../views/my-account/order-history/Order-history.page.vue'
import MyaccountOrderHistoryDetail from '../views/my-account/order-history/Order-history-detail.page.vue'
import ProductCategory from '../views/home/Category.page.vue'
import NewAddress from '../views/shopping-cart/checkout/delivery-options/New-address.page.vue'
import Contact from '../views/contact/Contact.page.vue'
import { userRepo } from '@/repositories/UserRepository'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { requiresAuth: false }
  },
  {
    path: '/request-password',
    name: 'requestPassword',
    component: RequestPassword,
    meta: { requiresAuth: false }
  },
  {
    path: '/reset-password/:code',
    name: 'resetPassword',
    component: ResetPassword,
    meta: { requiresAuth: false }
  },
  {
    path: '/access-granted/:id/:status',
    name: 'accessGranted',
    component: AccessGranted,
    meta: { requiresAuth: false }
  },
  {
    path: '/pick-address',
    name: 'pickAddress',
    component: PickAddress,
    meta: { requiresAuth: true }
  },
  {
    path: '/pick-company',
    name: 'pickCompany',
    component: PickCompany,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: { requiresAuth: true }
  },
  {
    path: '/shopping-cart',
    name: 'shopping-cart',
    component: ShoppingCart,
    meta: { requiresAuth: true }
  },
  {
    path: '/checkout/delivery',
    name: 'checkout-delivery',
    component: CheckoutDelivery,
    meta: { requiresAuth: true }
  },
  {
    path: '/checkout/billing',
    name: 'checkout-billing',
    component: CheckoutBilling,
    meta: { requiresAuth: true }
  },
  {
    path: '/checkout/remarks',
    name: 'checkout-remarks',
    component: CheckoutRemarks,
    meta: { requiresAuth: true }
  },
  {
    path: '/checkout/overview',
    name: 'checkout-overview',
    component: CheckoutOverview,
    meta: { requiresAuth: true }
  },
  {
    path: '/checkout/confirmation',
    name: 'checkout-confirmation',
    component: CheckoutOrderConfirmation,
    meta: { requiresAuth: true }
  },
  {
    path: '/myaccount/billing',
    name: 'myaccount-billing',
    component: MyaccountBilling,
    meta: { requiresAuth: true }
  },
  {
    path: '/myaccount/orders',
    name: 'myaccount-order-history',
    component: MyaccountOrderHistory,
    meta: { requiresAuth: true }
  },
  {
    path: '/myaccount/orders/:orderId',
    name: 'myaccount-order-history-detail',
    component: MyaccountOrderHistoryDetail,
    meta: { requiresAuth: true }
  },
  {
    path: '/product-category/:categoryId',
    name: 'productCategory',
    component: ProductCategory,
    meta: { requiresAuth: true }
  },
  {
    path: '/new-address',
    name: 'newAddress',
    component: NewAddress,
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!to.matched.some(record => record.meta.requiresAuth))
    return next()

  const user = await userRepo.getLoggedInUserDetails();
  if (user == null) {
    next({
      name: 'login',
      params: { nextUrl: to.fullPath },
      query: { redir: to.fullPath }
    })
    return;
  } else if (to.name != "pickAddress" && (user.addressId == null || user.addressId == 0)) {
    next({
      name: 'pickAddress',
      params: { nextUrl: to.fullPath },
      query: { redir: to.fullPath }
    })
    return;
  } else if (to.name != "pickCompany" && to.name != "pickAddress" && user.office == null) {
    next({
      name: 'pickCompany',
      params: { nextUrl: to.fullPath },
      query: { redir: to.fullPath }
    })
    return;
  }
  
  next()
});

export default router
