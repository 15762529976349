


























import {
  IProductCategory,
  productRepo,
} from "@/repositories/ProductRepository";
import Vue from "vue";
import ProductOverview from "@/components/ProductOverview.component.vue";
export default Vue.extend({
  name: "home",
  components: {
    ProductOverview
  },
  data: function () {
    return {
      category: null as IProductCategory | null,
      search: ""
    };
  },
  methods: {
    openCategory(category: IProductCategory): void {
      this.$router.push({ name: "productCategory", params: { categoryId: category.id } })
    }
  },
  mounted: async function () {
    this.category = await productRepo.getProductCategoryById(this.$route.params.categoryId);
  }
});
