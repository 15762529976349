var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('section',{staticClass:"block block--margin"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"sixteen"},[_c('div',{staticClass:"\n            narrow-content narrow-content--small narrow-content--center\n            text-align-center\n          "},[_vm._m(0),_c('p',[_vm._v(_vm._s(_vm.$t('setNewPassword')))]),_c('div',{staticClass:"loginbox"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-alert',{attrs:{"variant":"danger","show":_vm.formError ? true : false}},[_vm._v(" "+_vm._s(_vm.formError)+" ")]),_c('b-form-group',[_c('b-input-group',{attrs:{"size":"lg","prepend":"*"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('plhPassword'),"id":"password","type":"password","trim":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),(_vm.$v.form.password.$dirty && !_vm.$v.form.password.required)?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("requiredField"))+" ")]):_vm._e(),(
                    _vm.$v.form.password.$dirty && !_vm.$v.form.password.minLength
                  )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("minChars"))+" ")]):_vm._e()],1),_c('b-form-group',[_c('b-input-group',{attrs:{"size":"lg","prepend":"*"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('plhRepeatPassword'),"type":"password","trim":""},model:{value:(_vm.form.repeatPassword),callback:function ($$v) {_vm.$set(_vm.form, "repeatPassword", $$v)},expression:"form.repeatPassword"}})],1),(
                    _vm.$v.form.repeatPassword.$dirty &&
                    !_vm.$v.form.repeatPassword.required
                  )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("requiredField"))+" ")]):_vm._e(),(
                    _vm.$v.form.repeatPassword.$dirty &&
                    !_vm.$v.form.repeatPassword.sameAs
                  )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("passwordsDoNotMatch"))+" ")]):_vm._e()],1),_c('b-button',{staticClass:"button--full to-uppercase",attrs:{"type":"submit","variant":"primary","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("Change")))],1)],1)],1)])]),_c('div',{staticClass:"clear"})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo logo--center"},[_c('img',{attrs:{"src":require("@/assets/logo.svg"),"alt":"Logo Rosseel NV"}})])}]

export { render, staticRenderFns }