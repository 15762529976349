












































import Vue from "vue";
import moment from 'moment';
import { accountRepo } from "@/repositories/MyAccountRepository";
var debounce = require('debounce');

export default Vue.extend({
  name: "myaccount-order-history",
  components: {

  },
  data: function () {
    return {
      orders: [] as any[],
      search: ""
    };
  },
  mounted: async function () {
    this.orders = await accountRepo.getOrderHistory(this.search);
  },
  methods: {
  },
  watch:{
    search: debounce(async function () {
        //@ts-ignore
        this.orders = await accountRepo.getOrderHistory(this.search);
      }, 500
    )
  }
});

Vue.filter('formatDate', function(value: any) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY HHumm')
    }
});
