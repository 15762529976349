















































import Vue from "vue";
import { IProduct, IProductFilter, productRepo } from "@/repositories/ProductRepository";
import ProductDetailOverlay from "@/components/ProductDetailOverlay.component.vue";

export default Vue.extend({
  name: "productOverview",
  props: {
    categoryId: {
      type: String,
      required: false,
    },
    searchString: {
      type: String,
      required: false,
    }
  },
  components: {
    ProductDetailOverlay
  },
  data() {
    return {
      products: [] as IProduct[],
      isLoading: true,
      typingTimer: undefined as number | undefined,
      types: [] as IProductFilter[],
      selectedType: undefined as IProductFilter | undefined
    };
  },
  methods: {
    fetchProducts: async function () {
      this.isLoading = true;      
      
      if (this.categoryId && this.searchString == "" && this.selectedType == undefined)
        this.products = await productRepo.getProductsByCategoryId(this.$route.params.categoryId);
      else this.products = await productRepo.getProducts(this.searchString, this.selectedType?.typeValue, this.selectedType?.typeIndex);

      this.isLoading = false;
    },
    openProductDetail: function (product: IProduct) {
      //@ts-ignore
      this.$refs.productDetail.openProduct(product.id)
    },
    filterByType: async function (type?: IProductFilter) {      
      this.selectedType = type;
      await this.fetchProducts();  
    }
  },
  mounted: async function (): Promise<void> {
    await this.fetchProducts()
    this.types = await productRepo.getFiltersByCategoryId(this.$route.params.categoryId);
  },
  watch: {
    searchString: function () {
      if (this.typingTimer) {
        clearTimeout(this.typingTimer)
      }

      this.typingTimer = setTimeout(() => {        
        this.fetchProducts();
      }, 500);
    },
  },
});
