import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vuelidate from 'vuelidate'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueI18n from 'vue-i18n';
import messages from './lang';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

Vue.use(Vuelidate)

Vue.config.productionTip = false
Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(fas)
Vue.use(VueSweetalert2)

// language
Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  messages
});

export const Vm = new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')


