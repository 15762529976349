























































import Vue from "vue";
import { IProduct, productRepo } from "@/repositories/ProductRepository";
import { IUser, userRepo } from "@/repositories/UserRepository";
import { INewBasketCollection, IBasketItem, checkoutRepo } from "@/repositories/CheckoutRepository";
import { EventBus } from "@/eventBus";

var debounce = require('debounce');
export default Vue.extend({
  name: "productDetailOverlay",

  data() {
    return {
      product: null as IProduct | null,
      isLoading: true,
      isVisible: false,
      value: 1,
      user: null as IUser | null
    };
  },
  methods: {
    openProduct: async function (productId: string) {
      this.user = await userRepo.getLoggedInUserDetails();

      this.isVisible = true;
      this.isLoading = true;
      this.product = await productRepo.getProductById(productId, 1, this.user);
      this.isLoading = false;
    },
    closeOverlay: function () {
      this.product = null;
      this.isVisible = false
    },
    valueChangeInput: debounce(async function (newValue: number) {
        const parsedVal = parseFloat(newValue.toString());
        //@ts-ignore
        var self = this;
        self.updateValue(parsedVal);
      }, 500
    ),
    valueChange: async function (newValue: number) {
        const parsedVal = parseFloat(newValue.toString());
        this.updateValue(parsedVal);
    },
    updateValue: async function (val: number) {
      if (!Number.isNaN(val)) {
        if (val <= 0) {
          this.value == 0;
        } else {
          this.value = val;
        }

        if (this.product != null) {
          this.isLoading = true;
          this.product = await productRepo.getProductById(this.product.id, val, this.user);
          this.isLoading = false;
        }
      }
    },
    addToBasket: async function (product: IProduct | null) {
      this.user = await userRepo.getLoggedInUserDetails();
      if (this.user != null && product != null) {
        var basket = {} as INewBasketCollection;
        basket.items = [];

        //Build basketitem
        var basketItem = {} as IBasketItem;
        basketItem.productId = product.id;
        basketItem.quantity = this.value;

        //Build collection        
        basket.items.push(basketItem);
        basket.timestamp = new Date();
        basket.officeId = this.user.office.officeId;
        basket.companyId = this.user.office.companyId;
        basket.customerAddressId = this.user.addressId;

        //Call api
        var result = await checkoutRepo.addToBasket(basket);
        this.closeOverlay();
        if (result.success){
          await checkoutRepo.setOrderId(result.orderId);
          var self = this;
          this.$bvToast.toast(self.$t("articleToastText").toString(), {
            title: self.$t("articleToastTitle").toString(),
            toaster: 'b-toaster-top-right',
            variant: 'success',
            autoHideDelay: 5000,
            solid: true
          });

          this.value = 1;
          EventBus.$emit('basket-updated');
        }

      }
    }
  }
});
