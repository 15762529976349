












































import { userRepo } from "@/repositories/UserRepository";
import Vue from "vue";

import CompanyCard from '@/components/login/CompanyCard.component.vue';

export default Vue.extend({
  name: "pickCompany",
  data: function () {
    return {
      companies: [] as any[],
      selectedCompany: null
    };
  },
  components: {
    CompanyCard,
  },
  methods: {
    pickOffice: async function (office: any) {      
      var user = await userRepo.getLoggedInUserDetails();
      if (user != null) {
        user.office = office;        
        await userRepo.saveLoggedInUserDetails(user);
      }

      this.$router.push({ name: "home" });
    },
    pickCompany: async function (companyId: any) {
        this.selectedCompany = companyId;
    }
  },
  created: async function () {
    this.companies = await userRepo.getUserCompanies();    
    if (this.companies.length == 0) {
      this.$router.push({ name: "home" });
      return;
    }
    else if (this.companies.length == 1){
      this.selectedCompany = this.companies[0].id;
    }
  },
  computed: {
    activeCompany(): any {
      return this.companies.filter((c) => {
        return c.id == this.selectedCompany;
      });
    }
  }
});
