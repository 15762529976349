<template>
    <div class="col-md-6 mb-4">
        <div class="card company text-center p-3">
            <div class="card-block">
                <h2><i class="icon-building"></i></h2>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="card card-block border-0 p-2 font-weight-bold">{{company.name}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "companyCardComponent",
  props: ['company'],
  data() {
    return {
    }
  },
  methods:{
    
  }
}
</script>