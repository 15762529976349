import { IPagingOptions } from "@/interfaces/PagingOptions"
import { IPagingResult, PagingResult } from "@/interfaces/PagingResult"
import Api from "@/libs/Api"
import axios from "axios"

/* class MockUserRepository implements IUserRepository {
  private users: IUser[] = [
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f0',
      firstName: 'Frank',
      lastName: 'Vercaemere',
      email: 'test@debugged.be',
      roles: []
    },
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f1',
      firstName: 'Francine',
      lastName: 'Joost',
      email: 'francine.joost@test.be',
      roles: []
    },
    {
      id: '38a52be4-9352-453e-af97-5c3b448652f2',
      firstName: 'Jean-Paul',
      lastName: 'Citru',
      email: 'jp.citru@test.be',
      roles: []
    }
  ]

  public async getAllUsers(pagingOptions: IPagingOptions): Promise<IPagingResult<IUser>> {
    const nbPreceding = (pagingOptions.page - 1) * pagingOptions.perPage
    return new PagingResult(this.users.length, pagingOptions.page, pagingOptions.perPage,
      this.users.slice(nbPreceding, nbPreceding + pagingOptions.perPage)
    )
  }

  public async getUserById(id: string): Promise<IUser | null> {
    return this.users.find(u => u.id == id) || null
  }

  public async createUser(user: IUser): Promise<IUser> {
    user.id = this.generateUUID()
    this.users.push(user)
    return user;
  }

  private generateUUID(): string {
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  public async deleteUser(userId: string): Promise<boolean> {
    const user = await this.getUserById(userId)

    if (user) {
      this.users.splice(this.users.indexOf(user), 1)

      return true
    }

    return false
  }

  public async updateUser(user: IUser): Promise<void> {

    let existingUser = await this.getUserById(user.id)
    existingUser = user
  }

  public async login(loginDetails: {
    email: string
    password: string,
    rememberMe: boolean,
  }): Promise<any | null> {
    if (loginDetails.email == 'test@debugged.be' && loginDetails.password == 'test1234') {
      localStorage.setItem('user', JSON.stringify(this.users[0]))
      return {
        success: true,
        user: this.users[0]
      }
    }
    else {
      return {
        success: false,
        error: 'InvalidLoginCombo'
      }
    }
  }

  public async sendLoginDetails(userId: string): Promise<void> {
    return;
  }

  public async logout(): Promise<void> {
    localStorage.removeItem('user')
  }

  public async getLoggedInUserDetails(): Promise<IUser | null> {
    if (localStorage.getItem('user'))
      return JSON.parse(localStorage.getItem('user') as string)
    else
      return null
  }
} */

class ApiUserRepository {

  public async checkEmail(email: string): Promise<string> {
    const res = await Api.getData("SignIn/checkEmail/?email=" + email);
    if (res.data.success)
      return res.data.status
    else
      return "unknown"
  }

  public async register(user: IUser): Promise<IUser> {
    const res = await Api.postData("SignIn/Register", user)

    return res.data
  }

  public async requestAccess(email: string): Promise<IUser> {
    const res = await Api.postData("SignIn/RequestLoginAccess", email)

    return res.data
  }

  public async grantAccess(id: any): Promise<any> {
    const res = await Api.getData("SignIn/GrantAccess?id=" + id + "&resend=true")

    return res.data
  }

  public async login(loginDetails: {
    email: string
    password: string,
    rememberMe: boolean,
  }): Promise<any | null> {
    try {
      const res = await Api.postData("SignIn/Login", { email: loginDetails.email, password: loginDetails.password })

      if (res.data.user) {
        localStorage.setItem("user", JSON.stringify(res.data.user))
        return {
          success: true,
          user: res.data.user
        }
      } else {
        return {
          success: false,
          error: 'InvalidLoginCombo'
        }
      }
    } catch (error: any) {
      if (error.response.status == 400) {

        return {
          success: false,
          error: 'InvalidLoginCombo'
        }
      } else {
        throw (error);
      }
    }
  }

  public async requestPasswordConfirmation(email: string): Promise<void> {
    const res = await Api.postData("SignIn/ForgotPassword", { email: email })
  }

  public async resetPassword(model: { password: string, code: string }): Promise<any | null> {
    const res = await Api.postData("SignIn/ResetPassword", model)

    return res.data
  }

  public async logout(): Promise<void> {
    localStorage.removeItem('user')
  }

  public async getLoggedInUserDetails(): Promise<IUser | null> {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user') as string)

      //return await this.getUserById(user.id)
      return user
    }
    else
      return null
  }

  public async saveLoggedInUserDetails(user: IUser): Promise<void> {
    localStorage.setItem('user', JSON.stringify(user))
  }

  public async getUserCompanies(): Promise<any[]> {
    const res = await Api.getData("SignIn/GetCompaniesAndOfficesOfCurrentUser");
    if (res.data.success)
      return res.data.companies as any[]
    else
      return [] as any[]
  }

  public async getUserAddresses(): Promise<any[]> {
    const res = await Api.getData("SignIn/GetAddressesOfCurrentUser");
    if (res.data.success)
      return res.data.addresses as any[]
    else
      return [] as any[]
  }
}

export interface IUser {
  office: any;
  firstname: string,
  lastname: string,
  id: string,
  email: string,
  roles: string[],
  companyName?: string,
  vatNumber?: string,
  addressId: number,
  customerAddress: any
}

export const userRepo = new ApiUserRepository();