
















































































import {
  IProductCategory,
  productRepo,
} from "@/repositories/ProductRepository";
import Vue from "vue";
import ProductOverview from "@/components/ProductOverview.component.vue";

export default Vue.extend({
  name: "home",
  components: {
    ProductOverview,
  },
  data: function () {
    return {
      categories: [] as IProductCategory[],
      search: "",
      selected: [],
    };
  },
  methods: {
    openCategory(category: IProductCategory): void {
      this.$router.push({
        name: "productCategory",
        params: { categoryId: category.id },
      });
    },
    openAlert: async function(){
      var self = this;
      this.$swal({
        icon: "warning",
        title: self.$t("loadLatestOrder"),
        text: self.$t("loadLatestOrderText").toString(),
        confirmButtonColor: "#0E9203",
        showCancelButton: true,
        cancelButtonColor: "#ccc",
        confirmButtonText: self.$t("loadLatestOrderConfirm").toString(),
        cancelButtonText: self.$t("cancel").toString()
      }).then(async function (result){
        if (result.isConfirmed){
          var orderLoaded = await productRepo.loadMostRecentOrder();          
          if (orderLoaded.success){
            //@ts-ignore
            self.$router.push({name: "shopping-cart"});
          }
        }
      });
    }
  },
  mounted: async function () {
    this.categories = await productRepo.getProductCategories();
  },
});
