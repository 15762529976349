




























































//ZIE GIT HISTORY VOOR ANDERE WERKING
import Vue from "vue";  
import { checkoutRepo } from "@/repositories/CheckoutRepository";
import { IUser, userRepo } from "@/repositories/UserRepository";
export default Vue.extend({
  name:"checkout-delivery",
  components: {
    
  },
  mounted: async function(){
    this.user = await userRepo.getLoggedInUserDetails();         
    if (this.user != null) {
      this.selectedPickupLocation = this.user.office;
      this.deliveryAddresses = await checkoutRepo.getAddresses(this.user.customerAddress.addressId);
      if (this.deliveryAddresses.length == 1){
        this.selectedDeliveryPoint = this.deliveryAddresses[0]
      }
    }
  },
  data: function () {
    return {
      user: null as IUser | null,
      deliveryOption: "",
      options: [
        {
          html: '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="warehouse" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-warehouse fa-w-20"><path fill="currentColor" d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z" class=""></path></svg> ' + this.$t("pickup"),
          value: 'pickup'
        },
        {
          html: '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="truck" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-truck fa-w-20"><path fill="currentColor" d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z" class=""></path></svg> ' + this.$t("delivery"),
          value: 'delivery'
        },
      ],
      selectedPickupLocation: null as any | null,
      selectedDeliveryPoint: null as any | null,
      deliveryAddresses: [] as any[]
    };
  },
  methods: {
    selectDeliveryPoint: function (address: any){
      this.selectedDeliveryPoint = address;
    },
    SaveDeliveryInfo: async function (){
      if (this.deliveryOption == "pickup"){
        this.selectedDeliveryPoint = null;
      }else{
        this.selectedPickupLocation = null;
      }
      
      var savedDeliveryInfo = await checkoutRepo.saveDeliveryInfo(this.selectedPickupLocation?.officeId, this.selectedDeliveryPoint?.id);
      if (savedDeliveryInfo){
        this.$router.push({name: "checkout-remarks"});
      }
    },
    getOfficeLocation: function(name: string){
      if (name == "ROSSEEL"){
        return "Tielt"
      }else if (name == "HEMERYCK"){
        return "Ruddervoorde"
      }
    },
    newAddress: function(){
      this.$router.push({ name: "newAddress" })
    }
  }
});
