

































































import { userRepo } from "@/repositories/UserRepository";
import Vue from "vue";
import { email, required } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "requestPassword",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      isLoading: false,
      submitted: false,
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
      },
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.isLoading = false;
        return;
      }

      await userRepo.requestPasswordConfirmation(this.form.email)
      this.submitted = true;
      this.$v.$reset();

      this.isLoading = false;
    },
  },
});
