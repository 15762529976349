


















import Vue from "vue";
import { checkoutRepo } from "@/repositories/CheckoutRepository";
export default Vue.extend({
  name:"checkout-confirmation",
  components: {
    
  },
  mounted: async function(){
    this.orderId = await checkoutRepo.getOrderId();      
    await checkoutRepo.setOrderId("00000000-0000-0000-0000-000000000000");
  },
  data: function () {
    return {
      orderId: null as any | null
    };
  },
  methods: {
  }
});
