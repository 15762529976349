import Api from "@/libs/Api"
//import { IUser } from "./UserRepository"

class ApiAccountRepository {
  public async getOrderHistory(search: string): Promise<any[]> {
    const res = await Api.getData("MyAccount/GetOrderHistory?search=" + search);    
    if (res.data.success)
      return res.data.orders as any[]
    else
      return [] as any[]
  }

  public async getOrderDetails(orderId: any): Promise<any | null> {
    const res = await Api.getData("MyAccount/GetOrderDetails?orderId=" + orderId);    
    
    return res.data;
  }
}

export interface IUser {
  office: any;
  firstname: string,
  lastname: string,
  id: string,
  email: string,
  roles: string[],
  companyName?: string,
  vatNumber?: string,
  addressId: number
}

export const accountRepo = new ApiAccountRepository();