<template>
  <div class="loginbox">

    <b-alert variant="success" :show="accessRequested">
      {{ $t("accessRequested") }}
    </b-alert>

    <b-form @submit="onSubmit" v-if="!accessRequested">
      <b-alert variant="danger" :show="formError ? true : false">
        {{ formError }}
      </b-alert>

      <b-form-group>
        <!--emailadres-->
        <b-input-group size="lg" prepend="@">
          <b-form-input
            :disabled="existingMember"
            :placeholder="$t('plhEmail')"
            id="email"
            v-model="form.email"
            trim
          ></b-form-input>
        </b-input-group>
        <!--end emailadres-->
        <div class="text-align-right margin-top-10">
<a v-if="existingMember" href="#" @click.prevent="changeEmail"
          >{{ $t("changeEmail") }}</a
        >
        </div>
        
        <div
          class="text-danger"
          v-if="$v.form.email.$dirty && !$v.form.email.required"
        >
          {{ $t("requiredField") }}
        </div>
        <div
          class="text-danger"
          v-if="$v.form.email.$dirty && !$v.form.email.email"
        >
          {{$t("validEmail")}}
        </div>
      </b-form-group>
      <b-form-group v-if="existingMember && !requestAccess">
        <!--password-->
        <b-input-group size="lg" prepend="*">
          <b-form-input
            :placeholder="$t('plhPassword')"
            id="password"
            v-model="form.password"
            type="password"
            trim
          ></b-form-input>
        </b-input-group>
        <!--password-->
        <div
          class="text-danger"
          v-if="$v.form.password.$dirty && !$v.form.password.required"
        >
          {{ $t("requiredField") }}
        </div>
      </b-form-group>

      <b-alert variant="info" :show="requestAccess">
        {{ $t("requestAccess") }}
      </b-alert>
      <b-button
        v-if="!requestAccess"
        type="submit"
        variant="primary"
        class="button--full to-uppercase"
        :disabled="isLoading"
        ><b-spinner v-if="isLoading" small></b-spinner>
        {{ existingMember ? $t("login") : $t("proceed") }}</b-button
      >
      <b-button
        v-else
        type="button"
        @click="requestLoginAccess"
        variant="primary"
        class="button--full to-uppercase"
        :disabled="isLoading"
        ><b-spinner v-if="isLoading" small></b-spinner> {{ $t("request") }}</b-button
      >
      <div class="text-align-right">
        <router-link v-if="!requestAccess" :to="{ name: 'requestPassword' }">
          {{ $t("passwordForgotten") }}
          </router-link>
      </div>      
    </b-form>
  </div>
</template>

<script>
import { userRepo } from "@/repositories/UserRepository";
import { required, email, requiredIf } from "vuelidate/lib/validators";
export default {
  name: "loginboxComponent",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      existingMember: false,
      isLoading: false,
      formError: "",
      requestAccess: false,
      accessRequested: false,
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required: requiredIf(function () {
            return this.existingMember && !this.requestAccess;
          }),
        },
      },
    };
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      this.isLoading = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.isLoading = false;
        return;
      }

      if (this.existingMember) {
        var res = await userRepo.login({
          email: this.form.email,
          password: this.form.password,
          rememberMe: false,
        });
        
        //zie  ook regel in index.ts "beforeEach"
        if (res.success) this.$router.push({ name: "pickAddress" });
        else
          this.formError = this.$t("badLogin");
      } else {
        var status = await userRepo.checkEmail(this.form.email);

        if (status == "UnknownUser") {
          this.$router.push({
            name: "register",
            query: { email: this.form.email },
          });
        } else if (status == "KnownInactiveUser") {
          this.requestAccess = true;
          this.existingMember = true;
        } else if (status == "ActiveUser") {
          this.existingMember = true;
        } else {
          this.formError = this.$t("unexpectedError");
        }
      }

      this.$v.$reset();

      this.isLoading = false;
    },
    requestLoginAccess: async function () {
      this.isLoading = true;
      await userRepo.requestAccess(this.form.email);
      this.isLoading = false;
      this.accessRequested = true;
    },
    changeEmail: function () {
      this.existingMember = false;
      this.requestAccess = false;
      this.formError = "";
    },
  },
};
</script>