


















import Vue from "vue";
import { checkoutRepo } from "@/repositories/CheckoutRepository";
export default Vue.extend({
  name:"checkout-remarks",
  components: {
    
  },
  data: function () {
    return {
      text: ""
    };
  },
  mounted: async function () {
    this.text = await checkoutRepo.getRemark();
  },
  methods: {
    SaveRemark: async function(){
      var savedRemark = await checkoutRepo.saveRemark(this.text);
      if (savedRemark){
        this.$router.push({name: "checkout-overview"});
      }
    }
  }
});
