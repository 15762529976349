


































import Vue from "vue";
import { userRepo } from "@/repositories/UserRepository";
export default Vue.extend({
  name: "accessGranted",
  data() {
    return {
      id: this.$route.params.id,
      status: this.$route.params.status
    };
  },
  methods:{
    resendInfo: async function(){
      var response = await userRepo.grantAccess(this.id);      
      if (response.success){
        this.$router.push({ name: "accessGranted", params: { id: response.id, status: response.status } });
        window.location.reload();
      }
    }
  }
});
