













































































import Vue from "vue";
//import moment from 'moment';
import { accountRepo } from "@/repositories/MyAccountRepository";
import { IBasket } from "@/repositories/CheckoutRepository";
import { IUser } from "@/repositories/UserRepository";
import { productRepo } from "@/repositories/ProductRepository";
export default Vue.extend({
  name: "myaccount-order-history-detail",
  components: {

  },
  data: function () {
    return {
      user: null as IUser | null,
      basket: null as IBasket | null,
      orderDate: null as any | null,
      orderId: null as any | null,
      orderRef: "",
      orderNr: "",
      deliveryOption: "",
      pickupLocation: null as any | null,
      deliveryPoint: null as any | null,
      facturationAddress: null as any | null,
      remark: ""
    };
  },
  mounted: async function () {
    this.orderId = this.$route.params.orderId;

    var orderInfo = await accountRepo.getOrderDetails(this.orderId);
    if (orderInfo.success){
      this.orderNr = orderInfo.orderNr;
      this.orderRef = orderInfo.orderRef;
      this.orderDate = orderInfo.orderDate;      
      this.basket = orderInfo.basket;
      this.deliveryOption = (orderInfo.pickup ? "pickup" : "delivery");
      this.pickupLocation = orderInfo.pickupLocation;
      if (orderInfo.deliveryAddress != undefined) {
        this.deliveryPoint = orderInfo.deliveryAddress;
      }
      if (orderInfo.facturationAddress != undefined) {
        this.facturationAddress = orderInfo.facturationAddress;
      }
      this.remark = orderInfo.remark;
    }
  },
  methods: {
    replaceOrder: async function(){      
      var self = this;
      this.$swal({
        icon: "warning",
        title: self.$t("orderAgainButton"),
        text: self.$t("orderAgainText").toString(),
        confirmButtonColor: "#0E9203",
        showCancelButton: true,
        cancelButtonColor: "#ccc",
        confirmButtonText: self.$t("loadLatestOrderConfirm").toString(),
        cancelButtonText: self.$t("cancel").toString()
      }).then(async function (result){
        if (result.isConfirmed){
          var orderLoaded = await productRepo.loadSelectedOrder(self.orderId);          
          if (orderLoaded.success){
            //@ts-ignore
            self.$router.push({name: "shopping-cart"});
          }
        }
      });
    },
    getOfficeLocation: function(name: string){
      if (name == "ROSSEEL"){
        return "Tielt"
      }else if (name == "HEMERYCK"){
        return "Ruddervoorde"
      }
    }
  }
});

// Vue.filter('formatDate', function(value: any) {
//     if (value) {
//         return moment(String(value)).format('DD/MM/YYYY hhumm')
//     }
// });
