<template>
  <header class="header">
    <div class="inner-header wrapper">
      <!--main-header-->
      <section class="main-header">
          <!-- <div class="container"> -->
              <div class="sixteen">
                  <div class="main-header__inner">
                      <!--left-side-->
                      <div class="left-side">
                          <!--logo-->
                          <router-link :to="{name: 'home'}" class="logo">
                              <img src="@/assets/logo.svg" alt="Logo Rosseel NV" />
                          </router-link>
                          <!--end logo-->
                      </div>                            
                      <!--end left-side-->
                  
                      <!--right-side-->
                      <div class="right-side">
                          <!--hamburger-->
                          <b-button v-b-toggle.main-nav class="hamburger hamburger--only-icon">
                            <span>
                                  Menu
                              </span>
                              <i class="icon-menu"></i>
                          </b-button>
                          <!--end hamburger-->
                      </div>
                      <!--end right-side-->
                  </div>                            
              </div>
              <div class="clear"></div>
          <!-- </div> -->
      </section>
      <!--end main-header-->
    </div>

    <b-sidebar
      id="main-nav"
      title=""
      width="480px"
      shadow
      backdrop
    >
      <div class="px-3 py-2">
        
        <div class="language-options">
          <fieldset class="form-group" id="__BVID__01">		
            <div>
              <div id="languageOption" role="radiogroup" tabindex="-1" class="btn-group-toggle btn-group btn-group-md bv-no-focus-ring">
                  <label class="btn btn-outline-primary btn-md" :class="{ 'active': language == 'nl' }">
                    <input id="deliveryOption_BV_option_0" type="radio" name="delivery" class="" value="nl" v-model="language" @change="switchLanguage">
                    <span>NL</span>
                  </label>
                  <label class="btn btn-outline-primary btn-md" :class="{ 'active': language == 'en' }">
                    <input id="deliveryOption_BV_option_1" type="radio" name="delivery" class="" value="en" v-model="language" @change="switchLanguage">
                    <span>EN</span>
                  </label>
              </div>
            </div>
          </fieldset>
        </div>

        <nav class="side-nav">
          <ul>
            <li>
              <router-link :to="{name: 'myaccount-order-history'}">
                {{$t("menuHistory")}}
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'contact'}">
                {{$t("menuContact")}}
              </router-link>
            </li>
            <li>              
              <a href="" @click="logout">
                {{$t("menuSignOut")}}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </b-sidebar>
  </header>    
</template>

<script>
import { userRepo } from "@/repositories/UserRepository";
export default {
  name: "headerComponent",
  data: function() {
    return {
      language: ""
    }
  },
  created:function(){    
    this.language = (localStorage.getItem("culture") ?? "nl");
  },
  methods:{
    switchLanguage: function(){
      this.$i18n.locale = this.language;
      localStorage.setItem("culture", this.language);

      this.$emit("languageSwitched", true);
    },
    logout: function(){
      userRepo.logout();
    }
  },
  watch:{
    language: function(){     
      this.$i18n.locale = this.language;
      localStorage.setItem("culture", this.language);
    }
  }
}
</script>