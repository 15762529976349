






























































import Vue from "vue";
import { IBasket, IBasketItem, checkoutRepo } from "@/repositories/CheckoutRepository";
import { IUser, userRepo } from "@/repositories/UserRepository";
import { EventBus } from "@/eventBus";
var debounce = require('debounce');

export default Vue.extend({
  name: "shopping-cart",
  components: {

  },
  data: function () {
    return {
      //value: 1,
      user: null as IUser | null,
      basket: null as IBasket | null
    };
  },
  mounted: async function () {
    this.user = await userRepo.getLoggedInUserDetails();
    if (this.user != null) {
      this.basket = await checkoutRepo.getCurrentBasket(this.user);
    }
  },
  methods: {
    // getSubstring: function (text: string) {
    //   if (text.length > 70){
    //     return text.substring(0, 70) + "...";
    //   }

    //   return text;
    // },
    valueChangeInput: debounce(async function (newValue: number, item: any) {
      item.amount = newValue

      //@ts-ignore
      this.updateBasket(item)
    }, 500
    ),
    valueChange: async function (item: any, action: string) {
      if (action == "substract") {
        item.amount -= 1;
      }
      else if (action == "add") {
        item.amount += 1;
      }

      this.updateBasket(item)
    },
    updateBasket: async function (item: any) {      
      var basketItem = {} as IBasketItem;
      basketItem.productId = item.productId;
      basketItem.quantity = item.amount;

      var updatedItem = await checkoutRepo.updateBasketItem(basketItem);
      if (updatedItem) {
        //@ts-ignore
        this.basket = await checkoutRepo.getCurrentBasket(this.user);
        EventBus.$emit('basket-updated');
      }      
    },
    deleteItem: async function(item: any){
      var deletedItem = await checkoutRepo.deleteBasketItem(item.productId);
      if (deletedItem) {
        //@ts-ignore
        this.basket = await checkoutRepo.getCurrentBasket(this.user);
        EventBus.$emit('basket-updated');
      }
    }
  }
});
