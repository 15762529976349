import Api from "@/libs/Api"

class ApiProductRepository {
  public async getProductCategoryById(categoryId : string): Promise<IProductCategory | null> {
    const res = await Api.getData("Product/GetProductCategoryById?categoryId=" + categoryId)    

    return res.data.category
  }

  public async getProductCategories(): Promise<IProductCategory[]> {
    const res = await Api.getData("Product/GetProductCategories")
    return res.data.categories
  }

  public async getFiltersByCategoryId(categoryId : string): Promise<IProductFilter[]> {
    const res = await Api.getData("Product/GetFiltersByCategory?categoryId=" + categoryId)
    
    return res.data.filter_types
  }

  public async getProductsByCategoryId(categoryId : string): Promise<IProduct[]> {
    const res = await Api.getData("Product/GetProductsByCategory?categoryId=" + categoryId)
    
    return res.data.products
  }

  public async getProducts(searchString? : string, typeValue? : string, typeIndex? : number): Promise<IProduct[]> {    
    const res = await Api.getData(
      "Product/GetProducts?search=" + encodeURIComponent(searchString ?? "") + 
      "&typeValue=" + encodeURIComponent(typeValue ?? "") + 
      "&typeIndex=" + encodeURIComponent(typeIndex?.toString() ?? "")
    );

    return res.data.products
  }
  public async getProductById(id : string, amount: number, user: any): Promise<IProduct> {    
    const res = await Api.getData("Product/GetProductById?productId=" + id + "&amount=" + amount + "&officeId=" + user.office.officeId + "&addressId=" + user.addressId)
    
    return res.data.product;
  }

  public async loadMostRecentOrder(): Promise<any | null> {
    const user = JSON.parse(localStorage.getItem('user') as string);

    const res = await Api.getData("Product/LoadMostRecentOrder?officeId=" + user.office.officeId + "&addressId=" + user.addressId);    
    return res.data;
  }

  public async loadSelectedOrder(orderId: any): Promise<any | null> {
    const user = JSON.parse(localStorage.getItem('user') as string);    
    const res = await Api.getData("Product/LoadSelectedOrder?orderId=" + orderId + "&officeId=" + user.office.officeId + "&addressId=" + user.addressId);    
    return res.data;
  }
}

export interface IProduct {
  description: string;
  id: string;
  price: number;
  specs: any;
  unit: string;
  webshopColor: number;
}

export interface IProductCategory {
  description: string;
  id: string,
}

export interface IProductFilter {
  typeValue: string;
  typeDescription: string,
  typeIndex: number
}

export const productRepo = new ApiProductRepository();