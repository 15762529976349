import Api from "@/libs/Api"
import { IUser } from "./UserRepository"

class ApiCheckoutRepository {
  //#region Basket
  public async getCurrentBasket(user: IUser): Promise<IBasket | null> {        
    const res = await Api.getData("Basket/GetCurrentBasket?officeId=" + user.office.officeId + "&addressId=" + user.addressId)    
    await this.setOrderId(res.data.basket?.orderId);
    return res.data.basket
  }

  public async getBasketCount(): Promise<any | null> {        
    const res = await Api.getData("Basket/GetBasketCount")    
    if (res.data.success) {
      return res.data.basketItemsCount;
    }
    return 0;
  }

  public async addToBasket(basket: INewBasketCollection): Promise<any | null> {
    const res = await Api.postData("Basket/AddToBasket", basket);

    return res.data;
  }

  public async updateBasketItem(basketItem: IBasketItem): Promise<any | null> {    
    basketItem.orderId = localStorage.getItem('orderId');        
    const res = await Api.postData("Basket/UpdateItem", basketItem);

    return res.data.success;
  }

  public async deleteBasketItem(itemId: any): Promise<any | null> {    
    const basketItem = {} as IBasketItem;
    basketItem.productId = itemId;
    basketItem.orderId = localStorage.getItem('orderId');        
    
    const res = await Api.postData("Basket/DeleteItem", basketItem);
    return res.data.success;
  }
  //#endregion

  //#region Delivery
  public async getPickupLocations(): Promise<any | null> {    
    const res = await Api.getData("Basket/GetPickupLocations");

    return res.data.locations;
  }

  public async getAddresses(addressId: any): Promise<any | null> {    
    const res = await Api.getData("Basket/GetAddresses?addressId=" + addressId);        
    return res.data.deliveryAddresses;
  }

  public async getSelectedDeliveryInfo(): Promise<any | null> {    
    const res = await Api.getData("Basket/GetSelectedDeliveryInfo?orderId=" + localStorage.getItem('orderId'));

    return res.data;
  }

  public async saveDeliveryInfo(pickup: number, delivery: any): Promise<any | null> {
    const user = JSON.parse(localStorage.getItem('user') as string);    

    const deliveryInfo = {} as IDeliveryOptions;
    deliveryInfo.orderId = localStorage.getItem('orderId');
    deliveryInfo.pickupLocationId = pickup;
    deliveryInfo.deliveryAddressId = delivery;
    deliveryInfo.facturationAddressId = user.addressId;
    
    const res = await Api.postData("Basket/SaveDeliveryInfo", deliveryInfo);
    return res.data.success;
  }

  public async saveCustomDeliveryInfo(addressId: any): Promise<any | null> {
    const user = JSON.parse(localStorage.getItem('user') as string);    

    const deliveryInfo = {} as IDeliveryOptions;
    deliveryInfo.orderId = localStorage.getItem('orderId');
    deliveryInfo.customDeliveryAddressId = addressId;
    deliveryInfo.facturationAddressId = user.addressId;
    
    const res = await Api.postData("Basket/SaveDeliveryInfo", deliveryInfo);
    return res.data.success;
  }
  //#endregion

  //#region Remark
  public async getRemark(): Promise<any | null> {    
    const res = await Api.getData("Basket/GetRemark?orderId=" + localStorage.getItem('orderId'));

    return res.data.remark;
  }

  public async saveRemark(text: string): Promise<any | null> {
    const remark = {} as IRemark;
    remark.orderId = localStorage.getItem('orderId');
    remark.remark = text;

    const res = await Api.postData("Basket/SaveRemark", remark);
    return res.data.success;
  }
  //#endregion

  //#region Confirm order
  public async createTempMSoftOffer(orderId: any): Promise<any | null> {    
    const res = await Api.getData("Basket/CreateTempMSoftOffer?orderId=" + orderId);
    return res.data;
  }

  public async confirmOrder(customRef: string): Promise<any | null> {    
    const res = await Api.postData("Basket/ConfirmOrder", { orderId: localStorage.getItem('orderId'), customRef});    
    return res.data.success;
  }
  //#endregion
  public async setOrderId(orderId: any): Promise<void> {
    localStorage.setItem('orderId', orderId);
  }

  public async getOrderId(): Promise<string | null> {    
    return localStorage.getItem('orderId');
  }  

  public async saveAddress(address: IAddress): Promise<any | null> {
    const res = await Api.postData("Basket/SaveAddress", address)
    return res.data
  }
}

export interface IBasket {
  orderId: any,
  totalPrice: number,
  items: any
}

export interface INewBasketCollection {
  items: any,
  timestamp: Date,
  officeId: any,
  companyId: any,
  customerAddressId: any
}

export interface IBasketItem {
  orderId: any,
  productId: any,
  quantity: number
}

export interface IDeliveryOptions {
  orderId: any,
  pickupLocationId: number,
  deliveryAddressId: number,
  customDeliveryAddressId: any,
  facturationAddressId: number
}

export interface IRemark {
  orderId: any,
  remark: string
}

export interface IAddress {
  id: any,
  customerAddressId: number,
  name: string,
  phone: string,
  street: string,
  number: string,
  bus: string,
  postalcode: string,
  city: string
}

export const checkoutRepo = new ApiCheckoutRepository();