<template>
  <div id="app" class="wrapper" :key="componentKey">
    <header-component v-if="hasHeader" @languageSwitched="updateComponent"></header-component>

    <router-view></router-view>

    <footer-component v-if="hasFooter"></footer-component>
  </div>
</template>

<script>
  import HeaderComponent from '@/components/header/Header.component.vue';
  import FooterComponent from '@/components/footer/Footer.component.vue';
  
  export default {
    name: "App",
    components: {
      HeaderComponent,
      FooterComponent
    },
    data(){
      return{        
        pagesWithoutHeaderAndFooter: ["login", "requestPassword", "resetPassword", "accessGranted", "register", "pickCompany", "pickAddress"],
        componentKey: 0
      }      
    },    
    created: function(){    
      this.$i18n.locale = (localStorage.getItem("culture") ?? "nl");
    },
    methods: {
      updateComponent: function(){        
        this.componentKey += 1;
      }
    } ,
    computed:{
      hasHeader: function(){
         if(this.pagesWithoutHeaderAndFooter.includes(this.$route.name)){
          return false;
        }
        return true;
      },
      hasFooter: function(){
         if(this.pagesWithoutHeaderAndFooter.includes(this.$route.name)){
          return false;
        }
        return true;
      }
    },
  };
</script>

<style src="@/assets/styles/style.less" lang="less"></style>
