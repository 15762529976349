



























import { userRepo } from "@/repositories/UserRepository";
import Vue from "vue";

import CompanyCard from '@/components/login/CompanyCard.component.vue';

export default Vue.extend({
  name: "pickAddress",
  data: function () {
    return {
      addresses: [] as any[],
      selectedAddress: null
    };
  },
  components: {
    CompanyCard,
  },
  methods: {
    pickAddress: async function (address: any) {      
      var user = await userRepo.getLoggedInUserDetails();
      if (user != null) {
        user.addressId = address.addressId;    
        user.customerAddress = address;        
        await userRepo.saveLoggedInUserDetails(user);
      }

      this.$router.push({ name: "home" });
    }
  },
  created: async function () {
    this.addresses = await userRepo.getUserAddresses();    
    if (this.addresses.length == 0) {
      this.$router.push({ name: "home" });
      return;
    }
    else if (this.addresses.length == 1){      
      var user = await userRepo.getLoggedInUserDetails();
      if (user != null) {
        user.addressId = this.addresses[0].addressId;        
        user.customerAddress = this.addresses[0];        
        await userRepo.saveLoggedInUserDetails(user);

        this.$router.push({ name: "pickCompany" });
        return;
      }
    }
  }
});
