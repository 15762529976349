































































































import { userRepo } from "@/repositories/UserRepository";
import Vue from "vue";
import { minLength, required, sameAs } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "resetPassword",
  data() {
    return {
      form: {
        repeatPassword: "",
        password: "",
      },
      isLoading: false,
      formError: "",
    };
  },
  validations() {
    return {
      form: {
        password: {
          required,
          //@ts-ignore
          minLength: minLength(8),
        },
        repeatPassword: {
          required,
          sameAs: sameAs(function (): void {
             //@ts-ignore
            return this.form.password;
          }),
        },
      },
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.isLoading = false;
        return;
      }

      const res = await userRepo.resetPassword({
        password: this.form.password,
        code: this.$route.params.code,
      });

      if (res.success) {
        localStorage.setItem("user", JSON.stringify(res.user));
        this.$router.push({ name: "home" });
      }else{
        this.formError = res.error
      }

      this.isLoading = false;
    },
  },
});
