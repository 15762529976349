

















































































































import { userRepo } from "@/repositories/UserRepository";
import Vue from "vue";
import { email, required } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "requestPassword",
  data() {
    return {
      form: {
        email: "",
        phone: "",
        companyName: "",
        vatNumber: ""
      },
      isLoading: false,
      submitted: false,
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        companyName: {
          required
        },
        phone: {
          required
        },
        vatNumber: {
          required
        },
      },
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.isLoading = false;
        return;
      }

      //@ts-ignore
      await userRepo.register(this.form)

      this.submitted = true;
      this.$v.$reset();

      this.isLoading = false;
    },
  },
  mounted: function(){
    this.form.email = this.$route.query["email"]?.toString()
  }
});
