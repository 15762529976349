








import Vue from "vue";
export default Vue.extend({
  name:"myaccount-billing",
  components: {
    
  },
  data: function () {
    return {
      
    };
  },
  methods: {
  }
});
