


























import Vue from "vue";
import LoginBox from "@/components/login/Loginbox.component.vue";
export default Vue.extend({
  name: "login",  
  data: function () {
    return {};
  },
  components: {
    LoginBox,
  },
  methods: {

  },
});
